import React, { useEffect } from "react";
import { setEqualHeight } from "utils";

const EqualProfitContainer = ({
    equalSection,
    profit,
    makeEqualHeight = true,
    makeContainerEqualHeight = true,
    equalClass = "equal-container",
}) => {
    useEffect(() => {
        if (window.outerWidth > 767 && makeEqualHeight) {
            setEqualHeight("equal-section");
            makeContainerEqualHeight && setEqualHeight(equalClass);
        }
    }, []);
    
    return (
        <div className={`flex column info ${equalClass}`}>
            {typeof equalSection === "undefined" ? null : (
                <div className="">{typeof equalSection === "function" ? equalSection() : equalSection}</div>
            )}
            {typeof profit === "undefined" ? null : (
                <div className="profit">{typeof profit === "function" ? profit() : profit}</div>
            )}
        </div>
    );
};

export default EqualProfitContainer;
