import React from "react";

const ProgressBar = ({
    className = "",
    width = "100%",
    height = "20px",

    percent = "50%",
    borderRadius = "5px",
}) => {
    const outerStyle = {
        width,
        height,
        borderRadius: `${borderRadius}px`,
    };
    const innerStyle = {
        width: percent,
        height: "100%",
    };
    return (
        <div className={className} style={{ height: "29px" }}>
            <div className="progress_bar_wrap" style={outerStyle}>
                <div className="progress_bar_inner" style={innerStyle} />
            </div>
        </div>
    );
};

export default ProgressBar;
