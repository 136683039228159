import React from "react";

const MidRow = ({ left, right }) => (
    <div className="flex row profit-item">
        <span className="text-12 fw-500">{left}</span>
        <span className="text-14 fw-600">{right}</span>
    </div>
);

export default MidRow;
