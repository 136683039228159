import React from "react";
import { useFormattedMessage } from "hooks";
import ProgressBar from "components/ProgressBar";
import CustomFormatNumber from "components/CustomFormatNumber";

const messages = {
    butTag: "to_buy",
    sellTag: "to_sell",
    pausedTag: "paused",
};

const StatusRibbon = ({
    text = "",
    className = "",
    isProgressBar = false,
    percentage,
    pausedTag,
    buySellTag,
    buyCheapestPrice,
    sellHighestPrice,
}) => {
    const { formatMessage } = useFormattedMessage();
    return (
        <div className={`flex row availability ${className}`}>
            <span className="text-12 fw-600">{text}</span>

            {pausedTag && <span className="marketplace-paused-tag">{formatMessage(messages.pausedTag)}</span>}
            {buySellTag && (
                <div className="mr-4 d-flex">
                    <span className="marketplace-buy-tag mr-1">
                        {buyCheapestPrice && <CustomFormatNumber className="p-0 mr-1" value={buyCheapestPrice} />}
                        {formatMessage(messages.butTag)}
                    </span>
                    <span className="marketplace-sell-tag">
                        {sellHighestPrice && <CustomFormatNumber className="p-0 mr-1" value={sellHighestPrice} />}
                        {formatMessage(messages.sellTag)}
                    </span>
                </div>
            )}
            {isProgressBar && (
                <div className="flex row justify-center align-center progress">
                    <ProgressBar
                        height="4px"
                        width="44px"
                        percent={`${percentage}%`}
                        className="flex justify-center align-center"
                    />
                    <span className="text-14 fw-600">{percentage} %</span>
                </div>
            )}
        </div>
    );
};

export default StatusRibbon;
